import './Helper.scss';

import { Box } from "@mui/material";

import { CommonTitle } from "../Titles/Titles";

import { LocalFireDepartment } from "@mui/icons-material";
import { AssistWalker } from "@mui/icons-material";
import { PowerOff } from "@mui/icons-material";
import { Landslide } from "@mui/icons-material";
import { Support } from "@mui/icons-material";
import { Balcony } from "@mui/icons-material";
import { Vaccines } from "@mui/icons-material";
import { Flood } from "@mui/icons-material";
import { CarCrash } from "@mui/icons-material";
import { Masks } from "@mui/icons-material";
import { Volcano } from "@mui/icons-material";
import { FireTruck } from "@mui/icons-material";
import { Sos } from '@mui/icons-material';
import { Water } from "@mui/icons-material";
import { WaterDamage } from "@mui/icons-material";
import { MoreHoriz } from "@mui/icons-material";
import { PermPhoneMsg } from "@mui/icons-material";
import { Assignment } from "@mui/icons-material";

export const Fire = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <LocalFireDepartment/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Injury = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <AssistWalker/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const PowerOutage = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <PowerOff/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Collapse = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Landslide/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Accident = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Support/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const GettingStuck = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Balcony/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Disease = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Vaccines/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Flooding = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Flood/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const TrafficAccident = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <CarCrash/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const GasPollution = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Masks/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Explosion = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Volcano/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FireProtection = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <FireTruck/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SearchPeople = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Sos/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const OutdoorFountain = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Water/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Bottling = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <WaterDamage/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Other = ({property}) => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <MoreHoriz/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={property}/>
                </Box>
            </Box>
        </Box>
    )
}

export const LineType = () => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <PermPhoneMsg/>
                </Box>
                <Box className={"helper_container_title"}>
                    <CommonTitle property={"По линии ЧС"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const AgreementType = () => {

    return (
        <Box className={"helper"}>
            <Box className={"helper_container"}>
                <Box className={"helper_container_icon"}>
                    <Assignment/>
                </Box>
                <Box className={"helper_container_icon"}>
                    <CommonTitle property={"По договору"}/>
                </Box>
            </Box>
        </Box>
    )
}











