import './Dropdowns.scss';

import { useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

export const Filter = ({options, propertyLabel}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const toggleDropdown = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <div className={"filter"} onClick={toggleDropdown}>
            <div className={"filter_label"}>
                {selectedOption || (propertyLabel)}
            </div>
            {isOpen && (
                <ul className={"filter_menu"}>
                    {options.map((option) => (
                        <li
                            key={option.id}
                            value={option.id}
                            className={"filter_menu_item"}
                            onClick={() => handleOptionClick(option.name)}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export const EmergencyNameDropdown = ({emergencyOptions, handleSelect, error}) => {

    return (
        <Box className={"emergency_name_dropdown"}>
            <FormControl className={"emergency_name_dropdown_container"}>
                <InputLabel id="demo-simple-select-autowidth-label"> Наименование ЧС </InputLabel>
                <Select
                    id="demo-simple-select-autowidth"
                    className={"emergency_name_dropdown_container_select"}
                    labelId="demo-simple-select-autowidth-label"
                    autoWidth
                    label="Наименование ЧС"
                    onChange={handleSelect}
                    error={!!error}
                >
                    <MenuItem className={"emergency_name_dropdown_container_select_item"}>
                        Выберите наименование чс
                    </MenuItem>
                    {emergencyOptions.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            className={"emergency_name_dropdown_container_select_item"}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

export const TypeEmergencyDropdown = ({handleChange, error}) => {

    return (
        <Box className={"type_emergency_dropdown"}>
            <FormControl className={"type_emergency_dropdown_container"}>
                <InputLabel
                    id="demo-simple-select-autowidth-label"
                    className={"type_emergency_dropdown_container_inputlabel"}
                >
                    Вид донесения
                </InputLabel>
                <Select
                    id="demo-simple-select-autowidth"
                    className={"type_emergency_dropdown_container_select"}
                    labelId="demo-simple-select-autowidth-label"
                    autoWidth
                    label="Вид донесения"
                    onChange={handleChange}
                    error={!!error}
                >
                    <MenuItem
                        id={"line_emergency"}
                        className={"type_emergency_dropdown_container_select_item"}
                        value="line_emergency"
                    >
                        По линии ЧС
                    </MenuItem>
                    <MenuItem
                        id={"agreement_emergency"}
                        className={"type_emergency_dropdown_container_select_item"}
                        value="agreement_emergency"
                    >
                        По договору
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export const AgreementEmergencyDropdown = () => {
    const options = [
        {label: 'Шахта "Кузембаева"', id: "01"},
        {label:  "цех №6", id: "02"},
        {label: 'Шахта "Центральная"', id: "03"}
    ];

    return (
        <Box className={"agreement_emergency_dropdown"}>
            <FormControl className={"agreement_emergency_dropdown_container"}>
                <Autocomplete
                    className={"agreement_emergency_dropdown_container_select"}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={"agreement_emergency_dropdown_container_select_item"}
                            variant="outlined"
                            autoWidth
                            label="Объект по договору"
                        />
                    )}
                />
            </FormControl>
        </Box>
    )
}

export const ObjectOwnerDropdown = () => {
    const options = [
        { label:  'АО ТНК "КазХром"', id: "01" },
        { label: 'АО "Шубарколь комир"', id: "02" },
        { label:  'УД АО "Qarmet"', id: "03"}
    ]

    return (
        <Box className={"object_owner_dropdown"}>
            <FormControl className={"object_owner_dropdown_container"}>
                <Autocomplete
                    className={"object_owner_dropdown_container_select"}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={"object_owner_dropdown_container_select_item"}
                            label="Владелец объекта"
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
        </Box>
    )
}

export const TransportDropdown = ({transportOptions, handleSelect, error}) => {

    return (
        <Box className={"transport_dropdown"}>
            <FormControl className={"transport_dropdown_container"}>
                <InputLabel id="demo-simple-select-autowidth-label">Выбор транспорта</InputLabel>
                <Select
                    id="demo-simple-select-autowidth"
                    className={"transport_dropdown_container_select"}
                    labelId="demo-simple-select-autowidth-label"
                    autoWidth
                    label="Выбор транспорта"
                    onChange={handleSelect}
                    error={error}
                >
                    <MenuItem className={"transport_dropdown_container_select_item"}>
                        Выберите транспорт
                    </MenuItem>
                    {transportOptions.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            className={"transport_dropdown_container_select_item"}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}