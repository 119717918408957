import './BranchFilter.scss';

import { Box } from "@mui/material";

import { Filter } from "../../common-components/Dropdowns/Dropdowns";

const BranchFilter = () => {
    const options = [
        {
            id: "1",
            name: 'Актюбинский'
        },
        {
            id: "2",
            name: 'Атырауский'
        }
    ]

    return (
        <Box className={"branch_filter"}>
            <Filter options={options} propertyLabel={"Выберите филиал"}/>
        </Box>
    )
}

export default BranchFilter;