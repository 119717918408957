import './DetailsTools.scss';

import { Box } from "@mui/material";

import { SuccessChip } from "../../common-components/Chips/Chips";
import { ThirdBranch } from "../../common-components/Branches/Branches";

import { MiddleTitle } from "../../common-components/Titles/Titles";

const DetailsTools = () => {

    return (
        <Box className={"details_tools"}>
            <Box className={"details_tools_container"}>
                <Box className={"details_tools_container_title"}>
                    <MiddleTitle property={"Филиал:"}/>
                </Box>
                <Box className={"details_tools_container_chip"}>
                    <ThirdBranch/>
                </Box>
                <Box className={"details_tools_container_title"}>
                    <MiddleTitle property={"Статус:"}/>
                </Box>
                <Box className={"details_tools_container_chip"}>
                    <SuccessChip/>
                </Box>
            </Box>
        </Box>
    )
}

export default DetailsTools;