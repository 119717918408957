import { Box } from "@mui/material";

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { OwnerInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const OwnerField = ({errorMessage}) => {
    const doChange = useChange();
    const objectOwner = useSelector((state) => state.report.line_facility_owner);
    const objectOwnerValid = useSelector((state) => state.validation.fields);
    const [ownerProp, setOwnerProp] = useState(objectOwner);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={objectOwnerValid.line_facility_owner}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Владелец объекта"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <OwnerInput
                            value={ownerProp}
                            onChange={(e) => setOwnerProp(e.target.value)}
                            onBlur={() => doChange(ownerProp, 'line_facility_owner')}
                            error={!objectOwnerValid.line_facility_owner.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OwnerField;