import { Box } from "@mui/material";

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { EmergencyInformationInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const EmergencyInfoField = () => {
    const doChange = useChange();
    const emergencyInfo = useSelector((state) => state.report.emergency_info);
    const emergencyInfoValid = useSelector((state) => state.validation.fields);
    const [infoProp, setInfoProp] = useState(emergencyInfo);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={emergencyInfoValid.emergency_info}
                        />
                        <MiddleTitle property={"Данные по аварии"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <EmergencyInformationInput
                            value={infoProp}
                            onChange={(e) => setInfoProp(e.target.value)}
                            onBlur={() => doChange(infoProp, 'emergency_info')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default EmergencyInfoField;