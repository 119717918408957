import './App.scss';

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setAuthenticated } from "../slices/authSlice";

import { BrowserRouter as Router} from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { Box } from "@mui/material";

import Layout from "../components/Layout/Layout";
import MainPage from "../pages/MainPage/MainPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import ReportPage from "../pages/ReportPage/ReportPage";
import DeparturePage from "../pages/DeparturePage/DeparturePage";
import DetailsPage from "../pages/DetailsPage/DetailsPage";

const App = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        if (token && token !== 'undefined') {
            dispatch(setAuthenticated({token}));
        }
    }, [dispatch]);

    return (
        <Router>
            <Box className={"App"}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            isAuthenticated  || token ? <Navigate to="/MainPage" replace /> : <LoginPage/>
                        }
                    />
                    <Route
                        path="/MainPage"
                        element={
                            isAuthenticated || token ? <MainPage/> : <Navigate to="/" replace/>
                        }
                    />
                    <Route
                        path="/ReportPage"
                        element={
                            isAuthenticated || token ? (
                                <Layout
                                    children={<ReportPage/>}
                                />
                            ) : <Navigate to="/"/>
                        }
                    />
                    <Route
                        path="/DeparturePage"
                        element={
                            <Layout children={<DeparturePage/>}/>
                        }
                    />
                    <Route
                        path="/DetailsPage"
                        element={
                            <Layout children={<DetailsPage/>}/>
                        }
                    />
                </Routes>
            </Box>
        </Router>
    )
}

export default App;
