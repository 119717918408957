export const request = async (url, method, body, customHeaders = {}, credentials = 'include') => {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...customHeaders
    };

    const options = {
        method,
        body,
        headers,
        credentials
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(url, options);

        console.log("Response:", response);

        if (!response.ok) {
            throw new Error(`Could not fetch ${url}, status: ${response.status}`);
        }

        const data = await response.json();

        console.log("Response Data:", data);

        return data;
    } catch (e) {
        console.error("Fetch error:", e);
        throw e;
    }
}
