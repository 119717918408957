import '../Overviews.scss';

import { useDispatch } from "react-redux";

import { updateTransportQuantity } from "../../../slices/reportSlice";
import { removeTransport } from "../../../slices/reportSlice";

import { Box } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";
import { TypeOne } from "../../../common-components/Inputs/Inputs";
import { TypeTwo } from "../../../common-components/Inputs/Inputs";
import { TypeThree } from "../../../common-components/Inputs/Inputs";
import { TypeFore } from "../../../common-components/Inputs/Inputs";
import { TypeFive } from "../../../common-components/Inputs/Inputs";
import { TypeSix } from "../../../common-components/Inputs/Inputs";
import { TypeSeven } from "../../../common-components/Inputs/Inputs";

const TransportOverview = ({selectedTransports}) => {
    const dispatch = useDispatch();

    const handleQuantityChange = (id, value) => {
        const count = value;
        dispatch(updateTransportQuantity({id, count}));
    }

    const handleRemove = (id) => {
        dispatch(removeTransport(id));
    }

    const renderTransportComponent = (transport) => {
        switch (transport.id) {
            case "1":
                return <TypeOne
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "2":
                return <TypeTwo
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "3":
                return <TypeThree
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "4":
                return <TypeFore
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "5":
                return <TypeFive
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "6":
                return <TypeSix
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            case "7":
                return <TypeSeven
                    value={transport.count}
                    onChange={(value) => handleQuantityChange(transport.id, value)}
                    handleRemove={() => handleRemove(transport.id)}
                />
            default:
                return null
        }
    }

    return (
        <Box className={"overview"}>
            <Box className={"overview_container"}>
                {selectedTransports.map((transport) => (
                    <Box className={"overview_container_items"}>
                        <Box className={"overview_container_items_title"}>
                            <CommonTitle property={`тип: ${transport.name}`} color={"black"}/>
                        </Box>
                        <Box className={"overview_container_items_shower"}>
                            {renderTransportComponent(transport)}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default TransportOverview;