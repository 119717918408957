import './UsedInformation.scss';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { setFormField } from "../../../slices/reportSlice";

import { Box } from "@mui/material";

import TransportField from "../../Fields/TransportField/TransportField";
import OtherTechField from "../../Fields/OtherTechField/OtherTechField";
import WorkerField from "../../Fields/WorkerField/WorkerField";
import HumanHoursField from "../../Fields/HumanHoursField/HumanHoursField";
import OrganizationsField from "../../Fields/OrganizationsField/OrganizationsField";
import OtherInfoField from "../../Fields/OtherInfoField/OtherInfoField";

const UsedInformation = ({errorMessage}) => {

    return ( 
        <Box className={"technique_information"}>
            <Box className={"technique_information_container"}>
                <Box className={"technique_information_container_field"}>
                    <TransportField errorMessage={errorMessage}/>
                </Box>
                <Box className={"technique_information_container_field"}>
                    <WorkerField errorMessage={errorMessage}/>
                </Box>
                <Box className={"technique_information_container_field"}>
                    <HumanHoursField errorMessage={errorMessage}/>
                </Box>
                <Box className={"technique_information_container_field"}>
                    <OtherTechField/>
                </Box>
                <Box className={"technique_information_container_field"}>
                    <OrganizationsField/>
                </Box>
                <Box className={"technique_information_container_field"}>
                    <OtherInfoField/>
                </Box>
            </Box>
        </Box>
    )
}

export default UsedInformation;