import './Inputs.scss';

import { useState } from "react";

import { Box } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";

import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";

export const PasswordInput = ({password, setPassword}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickVisibility = () => {
        setShowPassword((show) => !show);
    };

    return (
        <Box className={"password_input"}>
            <FormControl className={"password_input_container"}>
                <InputLabel className={"password_input_container_helper"}>
                    Пароль
                </InputLabel>
                <OutlinedInput
                    className={"password_input_container_item"}
                    label="Пароль"
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                        <InputAdornment position={"end"}>
                            <IconButton
                                onClick={handleClickVisibility}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    )
}

export const LoginInput = ({email, setEmail}) => {

    return (
        <Box className={"login_input"}>
            <FormControl className={"login_input_container"}>
                <TextField
                    className={"login_input_container_item"}
                    label="Логин"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormControl>
        </Box>
    )
}

export const ReportingDateInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"reporting_date_input"}>
            <FormControl className={"reporting_date_input_container"}>
                <TextField
                    className={"reporting_date_input_container_item"}
                    type="datetime-local"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const ReportingPersonInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"reporting_person_input"}>
            <FormControl className={"reporting_person_input_container"}>
                <TextField
                    className={"reporting_person_input_container_item"}
                    label={"Кто произвел вызов"}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const StartingDateInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"staring_date_input"}>
            <FormControl className={"starting_date_input_container"}>
                <TextField
                    className={"starting_date_input_container_item"}
                    type="datetime-local"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const FinishDateInput = () => {

    return (
        <Box className={"finish_date_input"}>
            <FormControl className={"finish_date_input_container"}>
                <TextField
                    className={"finish_date_input_container_item"}
                    type="datetime-local"
                />
            </FormControl>
        </Box>
    )
}

export const LocationInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"location_input"}>
            <FormControl className={"location_input_container"}>
                <TextField
                    className={"location_input_container_item"}
                    type="text"
                    value={value}
                    label="Место возникновения"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const OwnerInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"owner_input"}>
            <FormControl className={"owner_input_container"}>
                <TextField
                    className={"owner_input_container_item"}
                    type="text"
                    value={value}
                    label="Владелец объекта"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}
export const EmergencyInformationInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"emergency_information_input"}>
            <FormControl className={"emergency_information_input_container"}>
                <TextField
                    className={"emergency_information_input_container_item"}
                    label="Данные по аварии"
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </FormControl>
        </Box>
    )
}

export const MinorInjuryInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"minor_injury_input"}>
            <FormControl className={"minor_injury_input_container"}>
                <TextField
                    className={"minor_injury_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const SevereInjuryInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"severe_injury_input"}>
            <FormControl className={"severe_injury_input_container"}>
                <TextField
                    className={"severe_injury_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const FatalInjuryInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"fatal_injury_input"}>
            <FormControl className={"fatal_injury_input_container"}>
                <TextField
                    className={"fatal_injury_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const EvacuatedPeopleInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"evacuated_people_input"}>
            <FormControl className={"evacuated_people_input_container"}>
                <TextField
                    className={"evacuated_people_input_container_item"}
                    label="Количество пострадавших"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const HealingPeopleInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"healing_people_input"}>
            <FormControl className={"healing_people_input_container"}>
                <TextField
                    className={"healing_people_input_container_item"}
                    label="Количество пострадавших"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    min={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const WorkInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"work_input"}>
            <FormControl className={"work_input_container"}>
                <TextField
                    className={"work_input_container_item"}
                    label="Перечень и объем проведенных работ"
                    type="text"
                    variant="outlined"
                    multiline
                    rows={6}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const SituationInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"situation_input"}>
            <FormControl className={"situation_input_container"}>
                <TextField
                    className={"situation_input_container_item"}
                    label="Ситуация на момент подачи донесения"
                    typ="text"
                    variant="outlined"
                    multiline
                    rows={6}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const OtherTechniqueInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"other_technique_input"}>
            <FormControl className={"other_technique_input_container"}>
                <TextField
                    className={"other_technique_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const WorkersCountInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"workers_count_input"}>
            <FormControl className={"workers_count_input_container"}>
                <TextField
                    className={"workers_count_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const HumanHoursInput = ({value, onChange, onBlur, error}) => {

    return (
        <Box className={"human_hours_input"}>
            <FormControl className={"human_hours_input_container"}>
                <TextField
                    className={"human_hours_input_container_item"}
                    label="Количеcтво"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                    error={error}
                />
            </FormControl>
        </Box>
    )
}

export const OtherOrganizationsInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"other_organizations_input"}>
            <FormControl className={"other_organizations_input_container"}>
                <TextField
                    className={"other_organizations_input_container_item"}
                    label="Количество"
                    type="number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{ min: 0 }}
                />
            </FormControl>
        </Box>
    )
}

export const OtherInput = ({value, onChange, onBlur}) => {

    return (
        <Box className={"situation_input"}>
            <FormControl className={"situation_input_container"}>
                <TextField
                    className={"situation_input_container_item"}
                    label="Дополнительная информация"
                    typ="text"
                    multiline
                    rows={6}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </FormControl>
        </Box>
    )
}

export const TypeOne = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                    <TextField
                        className={"type_input_container_item"}
                        label="тип: АЦ"
                        type="number"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleRemove}>
                                        <Delete sx={{color: "#f44336"}}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        inputProps={{min: 1}}
                    />
            </FormControl>
        </Box>
    )
}

export const TypeTwo = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="Оперативный а/м"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}

export const TypeThree = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="РПГ а/м"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}

export const TypeFore = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="Вахтовка"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}

export const TypeFive = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="Легковой а/м"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}

export const TypeSix = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="Легковой а/м пром. проходимости"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}

export const TypeSeven = ({value, onChange, handleRemove}) => {

    return (
        <Box className={"type_input"}>
            <FormControl className={"type_input_container"}>
                <TextField
                    className={"type_input_container_item"}
                    label="АСА а/м"
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRemove}>
                                    <Delete sx={{color: "#f44336"}}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{min: 1}}
                />
            </FormControl>
        </Box>
    )
}




