import './MainPage.scss';

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { showAlert } from "../../slices/authSlice";
import { clearAlert } from "../../slices/authSlice";

import { Box } from "@mui/material";

import { DarkLogo } from "../../common-components/Logo/Logo";
import { LargeTitle } from "../../common-components/Titles/Titles";
import { AlertSuccess } from "../../common-components/Alerts/Alerts";

import MenuGrid from "../../components/MenuGrid/MenuGrid";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";

const MainPage = () => {
    const dispatch = useDispatch();
    const successMessage = useSelector((state) => state.auth.successMessage);

    useEffect(() => {
        if (successMessage) {
            dispatch(showAlert());
            const timer = setTimeout(() => {
                dispatch(clearAlert());
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [successMessage, dispatch]);

    return (
        <Box className={"main_page"}>
            { successMessage && <AlertSuccess property={"Вы успешно авторизовались в систему"}/> }
            <Box className={"main_page_container"}>
                <Box className={"main_page_container_profile"}>
                    <ProfileMenu color={"black"}/>
                </Box>
                <Box className={"main_page_container_logo"}>
                    <DarkLogo/>
                </Box>
                <Box className={"main_page_container_title"}>
                    <LargeTitle property={"Выберите куда хотите перейти"}/>
                </Box>
                <Box className={"main_page_container_menu"}>
                    <MenuGrid/>
                </Box>
            </Box>
        </Box>
    )
}

export default MainPage;