import './DetailsUpdates.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { CommonTitle } from "../../common-components/Titles/Titles";
import { UpdateDate } from "../../common-components/Dates/Dates";

const DetailsUpdates = ({updates, detailsTitle}) => {

    return (
        <Box className={"details_updates"}>
            <Box className={"details_updates_container"}>
                <Box className={"details_updates_container_title"}>
                    <MiddleTitle property={detailsTitle}/>
                </Box>
                {updates.map((update) => (
                    <>
                        <Box className={"details_updates_container_date"}>
                            <Box className={"details_updates_container_date_title"}>
                                <CommonTitle property={"Дата: "}/>
                            </Box>
                            <Box className={"details_updates_container_date_chip"}>
                                <UpdateDate updateDate={update.date}/>
                            </Box>
                        </Box>
                        <Box className={"details_updates_container_description"}>
                            <CommonTitle property={update.value}/>
                        </Box>
                    </>
                ))}
            </Box>
        </Box>
    )
}

export default DetailsUpdates;