import './Alerts.scss';

import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertTitle } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const AlertSuccess = ({property}) => {
    const alertMessage = useSelector((state) => state.auth.alertMessage);

    return (
        <Box className={"alert_success"}>
            <Box className={`alert_success_container ${alertMessage ? "alert_enter" : "alert_exit"}`}>
                <Alert variant="filled" severity="success">
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}

export const AlertInfo = ({property}) => {
    const alertMessage = useSelector((state) => state.auth.alertMessage);

    return (
        <Box className={"alert_info"}>
            <Box className={`alert_info_container ${alertMessage ? "alert_enter" : "alert_exit"}`}>
                <Alert variant="filled" severity="info">
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}

export const AlertWarning = ({property}) => {

    return (
        <Box className={"alert_warning"}>
            <Box className={"alert_warning_container"}>
                <Alert variant="filled" severity="warning">
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}

export const AlertError = ({property}) => {
    const alertMessage = useSelector((state) => state.auth.alertMessage);

    return (
        <Box className={"alert_error"}>
            <Box className={`alert_error_container ${alertMessage ? "alert_enter" : "alert_exit"}`}>
                <Alert variant="filled" severity="error">
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}

export const AlertWarningModal = ({alertMessage, property}) => {

    return (
        <Box className={"alert_warning"}>
            <Box className={`alert_warning_container ${alertMessage ? "alert_enter" : "alert_exit"}`}>
                <Alert variant="filled" severity="warning">
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}

export const AlertErrorModal = ({alertMessage, property}) => {

    return (
        <Box className={"alert_error"}>
            <Box className={`alert_error_container ${alertMessage ? "alert_enter" : "alert_exit"}`}>
                <Alert variant="filled" severity="error">
                    <AlertTitle>
                        <MiddleTitle property={"Заполните обязательные поля:"}/>
                    </AlertTitle>
                    {property}
                </Alert>
            </Box>
        </Box>
    )
}