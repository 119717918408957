import './Indicator.scss';

import { Box } from "@mui/material";

import { WarningAmber } from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import { Check } from "@mui/icons-material";
import { Error } from "@mui/icons-material";

export const WarningIndicator = () => {

    return (
        <Box className={"indicator"}>
            <Box className={"indicator_container"}>
                <Box className={"indicator_container_icon"}>
                    <WarningAmber sx={{color: "#ff9800"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const AdditionallyIndicator = () => {

    return (
        <Box className={"indicator"}>
            <Box className={"indicator_container"}>
                <Box className={"indicator_container_icon"}>
                    <Info sx={{color: "#bdbdbd"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SuccessIndicator = () => {

    return (
        <Box className={"indicator"}>
            <Box className={"indicator_container"}>
                <Box className={"indicator_container_icon"}>
                    <Check sx={{color: "#4caf50"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FailureIndicator = () => {

    return (
        <Box className={"indicator"}>
            <Box className={"indicator_container"}>
                <Box className={"indicator_container_icon"}>
                    <Error sx={{color: "#f44336"}}/>
                </Box>
            </Box>
        </Box>
    )
}