import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { ReportingDateInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const CallDateField = ({errorMessage}) => {
    const doChange = useChange();
    const callDate = useSelector((state) => state.report.call_date);
    const callDateValid = useSelector((state) => state.validation.fields);
    const [callDateProp, setCallDateProp] = useState(callDate);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={callDateValid.call_date}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Дата и время вызова"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <ReportingDateInput
                            value={callDateProp}
                            onChange={(e) => setCallDateProp(e.target.value)}
                            onBlur={() => doChange(callDateProp, 'call_date')}
                            error={!callDateValid.call_date.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CallDateField;