import './Login.scss';

import { useState } from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { showAlert } from "../../slices/authSlice";
import { clearAlert } from "../../slices/authSlice";
import { loginUser } from "../../slices/authSlice";

import { Box } from "@mui/material";

import { LargeTitle } from "../../common-components/Titles/Titles";
import { LoginInput } from "../../common-components/Inputs/Inputs";
import { PasswordInput } from "../../common-components/Inputs/Inputs";
import { ButtonLogin } from "../../common-components/Buttons/Buttons";
import { AlertError } from "../../common-components/Alerts/Alerts";

const Login = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const token = useSelector((state) => state.auth.token);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!password || !email) {
             dispatch(showAlert());
        } else {
            dispatch(clearAlert());
        }

        dispatch(loginUser({email, password}));
    };

    useEffect(() => {

        if (isAuthenticated && token) {
            navigate('/MainPage');
        }

    }, [isAuthenticated, token, navigate]);


    return (
        <Box className={"login"}>
            { errorMessage && <AlertError property={"Ошибка, заполните поля для авторизации"}/> }
            <Box className={"login_container"}>
                <form className={"login_container_form"} onSubmit={handleSubmit}>
                    <Box className={"login_container_form_title"}>
                        <LargeTitle property={"Авторизация"}/>
                    </Box>
                    <Box className={"login_container_form_inputs"}>
                        <Box className={"login_container_form_inputs_login"}>
                            <LoginInput email={email} setEmail={setEmail}/>
                        </Box>
                        <Box className={"login_container_form_inputs_password"}>
                            <PasswordInput password={password} setPassword={setPassword}/>
                        </Box>
                    </Box>
                    <Box className={"login_container_form_button"}>
                        <ButtonLogin/>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default Login;