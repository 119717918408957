import '../Overviews.scss';

import { Box } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";

import { LineType } from "../../../common-components/Helper/Helper";
import { AgreementType } from "../../../common-components/Helper/Helper";

const TypeOverview = ({type}) => {

    const renderComponent = () => {
        if (type === true) {
            return <LineType/>
        } else if (type === false) {
            return <AgreementType/>
        } else {
            return null
        }
    }

    return (
        <Box className={"overview"}>
            <Box className={"overview_container"}>
                { type !== null ? (
                    <Box className={"overview_container_items"}>
                        <Box className={"overview_container_items_title"}>
                            <CommonTitle property={"Выбранный вид оперативного вызова"}/>
                        </Box>
                        <Box className={"overview_container_items_shower"}>
                            {renderComponent()}
                        </Box>
                    </Box>
                ) : null }
            </Box>
        </Box>
    )
}

export default TypeOverview;