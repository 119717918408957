import { Box } from "@mui/material";

import { useState } from "react";

import { useSelector } from "react-redux";

import { useChange } from "../../../hooks/useChange";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { ReportingPersonInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const CallerField = ({errorMessage}) => {
    const doChange = useChange();
    const caller = useSelector((state) => state.report.caller);
    const callerValid = useSelector((state) => state.validation.fields);
    const [callerProp, setCallerProp] = useState(caller);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={callerValid.caller}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Кто произвел вызов"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <ReportingPersonInput
                            value={callerProp}
                            onChange={(e) => setCallerProp(e.target.value)}
                            onBlur={() => doChange(callerProp, 'caller')}
                            error={!callerValid.caller.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CallerField;
