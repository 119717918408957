import './MainInformation.scss';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { setFormField } from "../../../slices/reportSlice";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import CallDateField from "../../Fields/CallDateField/CallDateField";
import CallerField from "../../Fields/CallerField/CallerField";
import WorkingStartField from "../../Fields/WorkingStartField/WorkingStartField";
import FinishDateField from "../../Fields/FinishDateField/FinishDateField";
import EmergencyField from "../../Fields/EmergencyField/EmergencyField";
import TypeField from "../../Fields/TypeField/TypeField";
import LocationField from "../../Fields/LocationField/LocationField";
import OwnerField from "../../Fields/OwnerField/OwnerField";
import EmergencyInfoField from "../../Fields/EmergencyInfoField/EmergencyInfoField";

const MainInformation = ({errorMessage}) => {

    return (
        <Box className={"main_information"}>
            <Box className={"main_information_container"}>
                <Box className={"main_information_container_field"}>
                    <CallDateField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <CallerField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <WorkingStartField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <FinishDateField/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <EmergencyField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <TypeField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <LocationField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <OwnerField errorMessage={errorMessage}/>
                </Box>
                <Box className={"main_information_container_field"}>
                    <EmergencyInfoField errorMessage={errorMessage}/>
                </Box>
            </Box>
        </Box>
    )
}

export default MainInformation;