import './DeparturePage.scss';

import { Box } from "@mui/material";

import { LargeTitle } from "../../common-components/Titles/Titles";
import { ButtonExport } from "../../common-components/Buttons/Buttons";

import Departures from "../../components/Departures/Departures";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DeparturesFilters from "../../components/DeparturesFilters/DeparturesFilters";
import Pagination from "../../components/Pagination/Pagination";

const DeparturePage = () => {

    return (
        <Box className={"departure_page"}>
            <Box className={"departure_page_container"}>
                <Box className={"departure_page_container_breadcrumbs"}>
                    <Breadcrumbs/>
                </Box>
                <Box className={"departure_page_container_header"}>
                    <Box className={"departure_page_container_header_title"}>
                        <LargeTitle property={"Оперативные выезды"} color={"blue"}/>
                    </Box>
                    <Box className={"departure_page_container_header_button"}>
                        <ButtonExport/>
                    </Box>
                </Box>
                <Box className={"departure_page_container_search"}>
                    <DeparturesFilters/>
                </Box>
                <Box className={"departure_page_container_table"}>
                    <Departures/>
                </Box>
                <Box className={"departure_page_container_pagination"}>
                    <Pagination/>
                </Box>
            </Box>
        </Box>
    )
}

export default DeparturePage;