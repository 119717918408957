import './MenuGrid.scss';

import { Box } from "@mui/material";

import { PromptDepartureCard } from "../../common-components/MenuCards/MenuCards";
import { ProphylacticDepartureCard } from "../../common-components/MenuCards/MenuCards";
import { ObjectBaseCard } from "../../common-components/MenuCards/MenuCards";
import { TechWorkCard } from "../../common-components/MenuCards/MenuCards";


const MenuGrid = () => {

    return (
        <Box className={"menu_grid"}>
            <Box className={"menu_grid_container"}>
                <Box className={"menu_grid_container_items"}>
                    <PromptDepartureCard/>
                </Box>
                <Box className={"menu_grid_container_items"}>
                    <ProphylacticDepartureCard/>
                </Box>
                <Box className={"menu_grid_container_items"}>
                    <ObjectBaseCard/>
                </Box>
                <Box className={"menu_grid_container_items"}>
                    <TechWorkCard/>
                </Box>
            </Box>
        </Box>
    )
}

export default MenuGrid;