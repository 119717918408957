import './Reports.scss';

import { Box } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";

import TableHeader from "./TableHeader/TableHeader";
import TableContent from "./TableContent/TableContent";

const Reports = () => {
    const reports = [
        {reporting_id: "1.1", who_call: "Johnsdsadasdasdsa", location_accident: "Placesadasdasdas", call_date: "02.04.2023 09:09", type_emergency: "Fire", status: true},
        {reporting_id: "1.1", who_call: "John", location_accident: "Place", call_date: "02.04.2023", type_emergency: "Firesdsadasdsdasd", status: false},
    ]

    return (
        <Box className={"operations_report"}>
            <TableContainer className={"operations_report_container"}>
                <Table className={"operations_report_container_table"}>
                    <TableHead className={"operations_report_container_table_header"}>
                        <TableHeader/>
                    </TableHead>
                    <TableBody className={"operations_report_container_table_body"}>
                        <TableContent reports={reports}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Reports;