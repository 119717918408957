import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../request/request";

const initialState = {
    call_date: '',
    caller: '',
    work_start_date: '',
    emergency_type: {},
    is_emergency_line: null,
    emergency_info: '',
    emergency_location: '',
    line_facility_owner: '',
    minor_injury_count: 0,
    severe_injury_count: 0,
    fatal_injury_count: 0,
    evacuated_people_count: 0,
    provided_medical_count: 0,
    work_scope: '',
    situation_during_filing: '',
    transport: [],
    client_equipment_count: 0,
    branch_worker_count: 0,
    worker_man_hours: 0,
    third_parties_count: 0,
    other_info: '',
    status: 'idle',
    errorMessage: null
};

export const sendReport = createAsyncThunk(
    'report/sendReport',
    async(reportData) => {
        try {
            const body = {
                data: {
                    type: "report",
                    attributes: reportData
                }
            }

            const response = await request(
                'http://api.resqcore.d/api/reports',
                'POST',
                body
            );

            return response;
        } catch (error) {
            return console.log(error.message);
        }
    }
);

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        addTransport: (state, action) => {
            const { id, name } = action.payload;

            state.transport.push({ id, name, count: 1 });
            state.errorMessage = null;
        },
        removeTransport: (state, action) => {
            state.transport = state.transport.filter(
                (transport) => transport.id !== action.payload
            );
        },
        updateTransportQuantity: (state, action) => {
            const { id, count } = action.payload;
            const transport = state.transport.find(
                (transport) => transport.id === id
            );
            if (transport) {
                transport.count = count;
            }
        },
        addEmergency: (state, action) => {
            const { id, name } = action.payload;
            state.emergency_type = { id, name };
        },
        addOperationsType: (state, action) => {
            state.is_emergency_line = action.payload;
        },
        setFormField: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        resetForm: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendReport.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendReport.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(sendReport.rejected, (state) => {
                state.status = 'error';
            })
    }
});

export const { addTransport, removeTransport, updateTransportQuantity, addEmergency, addOperationsType, setFormField, resetForm } = reportSlice.actions;
export default reportSlice.reducer;