import './ReportsFilters.scss';

import { Box } from "@mui/material";

import { FilterAlt } from "@mui/icons-material";

import TypeFilter from "../TypeFilter/TypeFilter";
import IntervalFilter from "../IntervalFilter/IntervalFilter";

const ReportsFilters = () => {

    return (
        <Box className={"reports_filters"}>
            <Box className={"reports_filters_container"}>
                <Box className={"reports_filters_container_icon"}>
                    <FilterAlt/>
                </Box>
                <Box className={"reports_filters_container_filter"}>
                    <TypeFilter/>
                </Box>
                <Box className={"reports_filters_container_filter"}>
                    <IntervalFilter/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportsFilters;