import { Box } from "@mui/material";

import { WarningIndicator } from "../../common-components/Indicator/Indicator";
import { AdditionallyIndicator } from "../../common-components/Indicator/Indicator";
import { SuccessIndicator } from "../../common-components/Indicator/Indicator";
import { FailureIndicator } from "../../common-components/Indicator/Indicator";

const IndicatorManager = ({field, errorMessage}) => {
    let indicatorType;

    if (errorMessage && !field.isValid) {
        indicatorType = <FailureIndicator/>;
    } else if (field.isRequired && !field.isValid) {
        indicatorType = <WarningIndicator/>;
    } else if (field.isValid) {
        indicatorType = <SuccessIndicator/>
    } else if (!field.isRequired && !field.isValid) {
        indicatorType = <AdditionallyIndicator/>
    }

    return (
        <Box className={"indicator_manager"}>
            <Box className={"indicator_manager_container"}>
                {indicatorType}
            </Box>
        </Box>
    )
}

export default IndicatorManager;