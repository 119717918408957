import './TypeFilter.scss';

import { Box } from "@mui/material";

import { Filter } from "../../common-components/Dropdowns/Dropdowns";

const TypeFilter = () => {
    const options = [
        {
            line_facility: true,
            name: 'По линии ЧС'
        },
        {
            line_facility: false,
            name: 'По договору'
        }
    ]

    return (
        <Box className={"type_filter"}>
            <Filter options={options} propertyLabel={"Вид оперативного выезда"}/>
        </Box>
    )
}

export default TypeFilter;