import './MenuLogout.scss';

import { Box } from "@mui/material";

import { Logout } from "@mui/icons-material";

import { MiddleTitle } from "../../common-components/Titles/Titles";

const MenuLogout = () => {

    return (
        <Box className={"logout"}>
            <Box className={"logout_container"}>
                <Box className={"logout_container_icon"}>
                    <Logout/>
                </Box>
                <Box className={"logout_container_title"}>
                    <MiddleTitle property={"Выйти"}/>
                </Box>
            </Box>
        </Box>
    )
}
export default MenuLogout;