import { configureStore } from "@reduxjs/toolkit";

import auth from '../slices/authSlice';
import report from '../slices/reportSlice';
import validation from '../slices/validateSlice';
import alert from '../slices/alertSlice';

const stringMiddleware = () => (next) => (action) => {
    if (typeof action === 'string') {
        return next({
            type:action
        })
    }
    return next(action);
};

const store = configureStore({
    reducer: {
        auth,
        report,
        validation,
        alert
    },
    middleware: getDefaultMiddleware =>
                getDefaultMiddleware()
                    .concat(stringMiddleware),
    devTools: {
        logOnly: false
    },
});

export default store;