import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { OtherTechniqueInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const OtherTechField = () => {
    const doChange = useChange();
    const otherTechCount = useSelector((state) => state.report.client_equipment_count);
    const otherTechValid = useSelector((state) => state.validation.fields);
    const [otherTechProp, setOtherTechProp] = useState(otherTechCount);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager field={otherTechValid.client_equipment_count}/>
                        <MiddleTitle property={"Иная техника"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <OtherTechniqueInput
                            value={otherTechProp}
                            onChange={(e) => setOtherTechProp(e.target.value)}
                            onBlur={() => doChange(otherTechProp, 'client_equipment_count')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OtherTechField;