import './TableContent.scss';

import { useState } from "react";
import { useEffect } from "react";

import { Box } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";

import BranchManager from "../../BranchManager/BranchManager";

const TableContent = ({reports}) => {
    const [maxLength, setMaxLength] = useState({
        line_facility_owner: 18,
        emergency_type: 18,
        emergency_location: 18,
        transport: 9
    })

    useEffect(() => {
        const handleSize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth <= 1600) {
                setMaxLength({
                    line_facility_owner: 11,
                    emergency_type: 11,
                    emergency_location: 11,
                    transport: 7
                });
            } else {
                setMaxLength({
                    line_facility_owner: 18,
                    emergency_type: 18,
                    emergency_location: 18,
                    transport: 9
                });
            }
        };

        window.addEventListener('resize', handleSize);

        handleSize();

        return () => {
            window.removeEventListener('resize', handleSize);
        }
    }, [])

    const sliceText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';

        }
        return text;
    }

    return (
        reports.map((rows) => (
            <TableRow className={`table_content ${rows.status === 'success' ? 'success' : 'danger'}`}>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={rows.reporting_id} color={"blue"}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={rows.work_start_date}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={rows.finish_work_date}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.line_facility_owner, maxLength.line_facility_owner)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.emergency_type, maxLength.emergency_type)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.emergency_location, maxLength.emergency_location)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={`${rows.branch_worker_count} чел, ${rows.worker_man_hours} чел.час`}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.transport, maxLength.transport)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="center" sx={{border: "none"}}>
                    <BranchManager branch={rows.branch}/>
                </TableCell>
            </TableRow>
        ))
    )
}

export default TableContent;