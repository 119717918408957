import './ReportPage.scss';

import { useState } from "react";

import { Box } from "@mui/material";
import { Divider } from "@mui/material";
import { Drawer } from "@mui/material";

import { LargeTitle } from "../../common-components/Titles/Titles";
import { ButtonAdd } from "../../common-components/Buttons/Buttons";

import Reports from "../../components/Reports/Reports";
import ReportingModal from "../../components/ReportingModal/ReportingModal";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ReportsFilters from "../../components/ReportsFilters/ReportsFilters";
import Pagination from "../../components/Pagination/Pagination";

const ReportPage = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleOpenDrawer = () => {
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    }

    return (
        <Box className={"report_page"}>
            <Box className={"report_page_container"}>
                <Box className={"report_page_container_breadcrumbs"}>
                    <Breadcrumbs/>
                </Box>
                <Box className={"report_page_container_header"}>
                    <Box className={"report_page_container_header_title"}>
                        <LargeTitle property={"Оперативные донесения"} color={"blue"}/>
                    </Box>
                    <Box className={"report_page_container_header_button"}>
                        <ButtonAdd onClick={handleOpenDrawer}/>
                    </Box>
                </Box>
                <Box className={"report_page_container_search"}>
                    <ReportsFilters/>
                </Box>
                <Box className={"report_page_container_table"}>
                    <Reports/>
                </Box>
                <Box className={"report_page_container_pagination"}>
                    <Pagination/>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                className={"drawer"}
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <ReportingModal/>
            </Drawer>
        </Box>
    )
}

export default ReportPage;