import { Box } from "@mui/material";

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { StartingDateInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const WorkingStartField = ({errorMessage}) => {
    const doChange = useChange();
    const workStartDate = useSelector((state) => state.report.work_start_date);
    const workStartDateValid = useSelector((state) => state.validation.fields);
    const [startProp, setStartProp] = useState(workStartDate);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={workStartDateValid.work_start_date}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Дата и время начала работ"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <StartingDateInput
                            value={startProp}
                            onChange={(e) => setStartProp(e.target.value)}
                            onBlur={() => doChange(startProp, 'work_start_date')}
                            error={!workStartDateValid.work_start_date.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default WorkingStartField;
