import './Department.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const Department = ({value, color}) => {

    return (
        <Box className={`department ${color}`}>
            <Box className={"department_container"}>
                <MiddleTitle property={value}/>
            </Box>
        </Box>
    )
}