import './Buttons.scss';

import { Box } from "@mui/material";
import { Button } from "@mui/material";

import { Login } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import { FileDownload } from "@mui/icons-material";
import { Send } from "@mui/icons-material";
import { Print } from "@mui/icons-material";

import { CommonTitle } from "../Titles/Titles";
import { MiddleTitle } from "../Titles/Titles";

export const ButtonLogin = () => {

    return (
        <Box className={"button_login"}>
            <Box className={"button_login_container"}>
                <Button
                    variant="contained"
                    className={"button_login_container_item"}
                    type="submit"
                    sx={{
                        backgroundColor: "#424242",
                        color: "#fff",
                        "&:hover" : {
                            backgroundColor: "#212121"
                        }
                    }}
                >
                    <Box className={"button_login_container_item_icon"}>
                        <Login/>
                    </Box>
                    <Box className={"button_login_container_item_title"}>
                        Войти
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}

export const ButtonAdd = ({onClick}) => {

    return (
        <button className={"button"} onClick={onClick}>
            <div className={"button_icon"}>
                <Add sx={{fontSize: "18px"}}/>
            </div>
            <div className={"button_title"}>
                Добавить
            </div>
        </button>
    )
}

export const ButtonExport = ({onClick}) => {

    return (
        <button className={"button"} onClick={onClick}>
            <div className={"button_icon"}>
                <FileDownload sx={{fontSize: "18px"}}/>
            </div>
            <div className={"button_title"}>
                Экспорт
            </div>
        </button>
    )
}

export const ButtonPrint = ({onClick}) => {

    return (
        <button className={"button"} onClick={onClick}>
            <div className={"button_icon"}>
                <Print sx={{fontSize: "18px"}}/>
            </div>
            <div className={"button_title"}>
                Печать
            </div>
        </button>
    )
}

export const ButtonSend = ({onClick}) => {

    return (
        <Box className={"button_send"}>
            <Box className={"button_send_container"}>
                <Button
                    variant="contained"
                    className={"button_send_container_item"}
                    onClick={onClick}
                    sx={{
                        backgroundColor: "#424242",
                        color: "#fff",
                        "&:hover" : {
                            backgroundColor: "#212121"
                        }
                    }}
                >
                    <Box className={"button_send_container_item_icon"}>
                        <Send/>
                    </Box>
                    <Box className={"button_send_container_item_title"}>
                        Отправить
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}

