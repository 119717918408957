import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { HumanHoursInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const HumanHoursField = ({errorMessage}) => {
    const doChange = useChange();
    const workerManHours = useSelector((state) => state.report.worker_man_hours);
    const workerHoursValid = useSelector((state) => state.validation.fields);
    const [workerHoursProp, setWorkerHoursProp] = useState(workerManHours);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={workerHoursValid.worker_man_hours}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Количество человеко часов"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <HumanHoursInput
                            value={workerHoursProp}
                            onChange={(e) => setWorkerHoursProp(e.target.value)}
                            onBlur={() => doChange(workerHoursProp, 'worker_man_hours')}
                            error={!workerHoursValid.worker_man_hours.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default HumanHoursField;