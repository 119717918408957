import './DeparturesFilters.scss';

import { Box } from "@mui/material";

import { FilterAlt } from "@mui/icons-material";

import BranchFilter from "../BranchFilter/BranchFilter";
import TypeFilter from "../TypeFilter/TypeFilter";
import IntervalFilter from "../IntervalFilter/IntervalFilter";

const DeparturesFilters = () => {

    return (
        <Box className={"departures_filters"}>
            <Box className={"departures_filters_container"}>
                <Box className={"departures_filters_container_icon"}>
                    <FilterAlt/>
                </Box>
                <Box className={"departures_filters_container_filter"}>
                    <BranchFilter/>
                </Box>
                <Box className={"departures_filters_container_filter"}>
                    <TypeFilter/>
                </Box>
                <Box className={"departures_filters_container_filter"}>
                    <IntervalFilter/>
                </Box>
            </Box>
        </Box>
    )
}

export default DeparturesFilters;