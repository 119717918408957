import './DetailsPage.scss';

import { Box } from "@mui/material";

import { LargeTitle } from "../../common-components/Titles/Titles";
import { ButtonPrint } from "../../common-components/Buttons/Buttons";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DetailsTools from "../../components/DetailsTools/DetailsTools";
import DetailsList from "../../components/DetailsList/DetailsList";

const DetailsPage = () => {

    return (
        <Box className={"details_page"}>
            <Box className={"details_page_container"}>
                <Box className={"details_page_container_breadcrumbs"}>
                    <Breadcrumbs/>
                </Box>
                <Box className={"details_page_container_header"}>
                    <Box className={"details_page_container_header_title"}>
                        <LargeTitle property={"Донесение #1-1"} color={"blue"}/>
                    </Box>
                    <Box className={"details_page_container_header_button"}>
                        <ButtonPrint/>
                    </Box>
                </Box>
                <Box className={"details_page_container_tools"}>
                    <DetailsTools/>
                </Box>
                <Box className={"details_page_container_info"}>
                    <DetailsList/>
                </Box>
            </Box>
        </Box>
    )
}

export default DetailsPage;