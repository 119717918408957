import { Box } from "@mui/material";

import { FirstBranch } from "../../common-components/Branches/Branches";
import { SecondBranch } from "../../common-components/Branches/Branches";
import { ThirdBranch } from "../../common-components/Branches/Branches";
import { FourthBranch } from "../../common-components/Branches/Branches";
import { FifthBranch } from "../../common-components/Branches/Branches";
import { SixthBranch } from "../../common-components/Branches/Branches";
import { SeventhBranch } from "../../common-components/Branches/Branches";
import { EighthBranch } from "../../common-components/Branches/Branches";
import { NinthBranch } from "../../common-components/Branches/Branches";
import { TenthBranch } from "../../common-components/Branches/Branches";
import { EleventhBranch } from "../../common-components/Branches/Branches";

const BranchManager = ({branch}) => {

    const renderBranch = () => {
        switch (branch.id) {
            case "1":
                return <FirstBranch/>
            case "2":
                return <SecondBranch/>
            case "3":
                return <ThirdBranch/>
            case "4":
                return <FourthBranch/>
            case "5":
                return <FifthBranch/>
            case "6":
                return <SixthBranch/>
            case "7":
                return <SeventhBranch/>
            case "8":
                return <EighthBranch/>
            case "9":
                return <NinthBranch/>
            case "10":
                return <TenthBranch/>
            case "11":
                return <EleventhBranch/>
            default:
                return null
        }
    }

    return (
        <Box className={"branch_manager"}>
            <Box className={"branch_manager_container"}>
                {renderBranch()}
            </Box>
        </Box>
    )
}

export default BranchManager;