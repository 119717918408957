import './Dates.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const CreatedDate = ({createdDate}) => {

    return (
        <Box className={"created_date"}>
            <Box className={"created_date_container"}>
                <Box className={"created_date_container_title"}>
                    <MiddleTitle property={createdDate}/>
                </Box>
            </Box>
        </Box>
    )
}

export const StartDate = ({startDate}) => {

    return (
        <Box className={"start_date"}>
            <Box className={"start_date_container"}>
                <Box className={"start_date_container_title"}>
                    <MiddleTitle property={startDate}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FinishDate = ({finishDate}) => {

    return (
        <Box className={"finish_date"}>
            <Box className={"finish_date_container"}>
                <Box className={"finish_date_container_title"}>
                    <MiddleTitle property={finishDate}/>
                </Box>
            </Box>
        </Box>
    )
}

export const UpdateDate = ({updateDate}) => {

    return (
        <Box className={"update_date"}>
            <Box className={"update_date_container"}>
                <Box className={"update_date_container_title"}>
                    <MiddleTitle property={updateDate}/>
                </Box>
            </Box>
        </Box>
    )
}