import './Departures.scss';

import { Box } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";

import TableHeader from "./TableHeader/TableHeader";
import TableContent from "./TableContent/TableContent";

const Departures = () => {
    const reports = [
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024 09:06',
            finish_work_date: '02.01.2024 09: 07',
            line_facility_owner: 'Ownerasdsadsadsadasdsadsadasdasdsad',
            emergency_type: 'Обеспечение противопожарной',
            emergency_location: 'Placeasdasdasdsaasdsadsadsadasdsad',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transportsadsadassadasdasdassdas',
            branch: {id: '1', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Отключение электричества',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '2', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Заболевание',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '3', name: 'Актюбинкий'},
            status: 'danger'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '4', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '5', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '6', name: 'Актюбинкий'},
            status: 'danger'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '7', name: 'Актюбинкий'},
            status: 'danger'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '8', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '9', name: 'Актюбинкий'},
            status: 'danger'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '10', name: 'Актюбинкий'},
            status: 'success'
        },
        {
            reporting_id: '1-1',
            work_start_date: '02.01.2024',
            finish_work_date: '02.01.2024',
            line_facility_owner: 'Owner',
            emergency_type: 'Fire',
            emergency_location: 'Place',
            branch_worker_count: 1,
            worker_man_hours: 2,
            transport: 'Transport',
            branch: {id: '11', name: 'Актюбинкий'},
            status: 'danger'
        },
    ]

    return (
        <Box className={"departures"}>
            <TableContainer className={"departures_container"}>
                <Table className={"departures_container_table"}>
                    <TableHead className={"departures_container_table_header"}>
                        <TableHeader/>
                    </TableHead>
                    <TableBody className={"departures_container_table_body"}>
                        <TableContent reports={reports}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Departures;