import './TableContent.scss';

import { useState } from "react";
import { useEffect } from "react";

import { Box } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { Update } from "@mui/icons-material";

import { CommonTitle } from "../../../common-components/Titles/Titles";
import { SuccessChip } from "../../../common-components/Chips/Chips";
import { ProcessChip } from "../../../common-components/Chips/Chips";

const TableContent = ({reports}) => {
    const [maxLength, setMaxLength] = useState({
        who_call: 18,
        location_accident: 18,
        type_emergency: 18
    });

    useEffect(() => {
        const handleSize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth <= 1600) {
                setMaxLength({
                    who_call: 11,
                    location_accident: 11,
                    type_emergency: 11
                });
            } else {
                setMaxLength({
                    who_call: 18,
                    location_accident: 18,
                    type_emergency: 18
                });
            }
        };

        window.addEventListener('resize', handleSize);

        handleSize();

        return () => {
            window.removeEventListener('resize', handleSize);
        }
    }, [])

    const sliceText = (text, maxLength) => {
        if (text.length  > maxLength) {
            return text.substring(0, maxLength) + '...';
        }

        return text;
    };

    return (
        reports.map((rows) => (
            <TableRow className={"table_content"}>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={rows.reporting_id}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={rows.call_date}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.who_call, maxLength.who_call)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.location_accident, maxLength.location_accident)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="left" sx={{border: "none"}}>
                    <CommonTitle property={sliceText(rows.type_emergency, maxLength.type_emergency)}/>
                </TableCell>
                <TableCell className={"table_content_cell"} align="center" sx={{border: "none"}}>
                    {rows.status === true ? <SuccessChip/> : <ProcessChip/>}
                </TableCell>
                <TableCell className={"table_content_cell"} align="center" sx={{border: "none"}}>
                    <Box className={"table_content_cell_update"}>
                        <Update sx={{fontSize: "20px"}}/>
                    </Box>
                </TableCell>
            </TableRow>
        ))
    )
}

export default TableContent;