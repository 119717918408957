import './ReportingModal.scss';

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useSubmit } from "../../hooks/useSubmit";

import { showAlert } from "../../slices/validateSlice";
import { removeAlert } from "../../slices/validateSlice";

import { Box } from "@mui/material";
import { Divider } from "@mui/material";

import { LargeTitle } from "../../common-components/Titles/Titles";
import { ButtonSend } from "../../common-components/Buttons/Buttons";
import { AlertErrorModal } from "../../common-components/Alerts/Alerts";
import { AlertWarningModal } from "../../common-components/Alerts/Alerts";

import MainInformation from "../ReportForms/MainInformation/MainInformation";
import AdditionalInformation from "../ReportForms/AdditionalInformation/AdditionalInformation";
import UsedInformation from "../ReportForms/UsedInformation/UsedInformation";

const ReportingModal = () => {
    const doSubmit = useSubmit();
    const formData = useSelector((state) => state.report);
    const errorAlert = useSelector((state) => state.alert.errorAlert);
    const warningAlert = useSelector((state) => state.alert.warningAlert);
    const errorMessage = useSelector((state) => state.alert.errorMessage);
    const warningMessage = useSelector((state) => state.alert.warningMessage);

    console.log('Warning Message:', warningMessage);

    const handleSubmit = () => {
        doSubmit(formData);
    }

    return (
        <Box className={"reporting_modal"}>
            <Box className={"reporting_modal_container"}>
                { errorAlert && errorMessage && (
                    <AlertErrorModal alertMessage={errorAlert} property={errorMessage}/>
                )}
                { warningAlert && warningMessage && (
                    <AlertWarningModal alertMessage={warningAlert} property={warningMessage}/>
                )}
                <Box className={"reporting_modal_container_form"}>
                    <Box className={"reporting_modal_container_form_field"}>
                        <Box className={"reporting_modal_container_form_field_title"}>
                            <LargeTitle property={"Основная информация"} color={"blue"}/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_divider"}>
                            <Divider/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_item"}>
                            <MainInformation errorMessage={errorMessage}/>
                        </Box>
                    </Box>
                    <Box className={"reporting_modal_container_form_field"}>
                        <Box className={"reporting_modal_container_form_field_title"}>
                            <LargeTitle property={"Дополнительные сведения"} color={"blue"}/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_divider"}>
                            <Divider/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_item"}>
                            <AdditionalInformation errorMessage={errorMessage}/>
                        </Box>
                    </Box>
                    <Box className={"reporting_modal_container_form_field"}>
                        <Box className={"reporting_modal_container_form_field_title"}>
                            <LargeTitle property={"Задействовано техники и работников"} color={"blue"}/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_divider"}>
                            <Divider/>
                        </Box>
                        <Box className={"reporting_modal_container_form_field_item"}>
                            <UsedInformation errorMessage={errorMessage}/>
                        </Box>
                    </Box>
                    <Box className={"reporting_modal_container_form_button"}>
                        <ButtonSend onClick={handleSubmit}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportingModal;