import './LoginPage.scss';

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { showAlert } from "../../slices/authSlice";
import { clearAlert } from "../../slices/authSlice";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { LightLogo } from "../../common-components/Logo/Logo";
import { AlertInfo } from "../../common-components/Alerts/Alerts";
import { AlertError } from "../../common-components/Alerts/Alerts";

import Reports from "../../components/Reports/Reports";

import Login from "../../components/Login/Login";

const LoginPage = () => {
    const dispatch = useDispatch();
    const infoMessage = useSelector((state) => state.auth.infoMessage);

    useEffect(() => {
        if (infoMessage) {
            dispatch(showAlert());
            const timer = setTimeout(() => {
                dispatch(clearAlert());
            }, 10000)

            return () => clearTimeout(timer);
        }
    }, [infoMessage, dispatch]);

    return (
        <Box className={"login_page"}>
            {infoMessage && <AlertInfo property={"Внимание, вы вышли из системы"}/>}
            <Box className={"login_page_container"}>
                <Box className={"login_page_container_authorization"}>
                    <Login/>
                </Box>
                <Box className={"login_page_container_logo"}>
                    <LightLogo/>
                </Box>
                <Box className={"login_page_container_title"}>
                    <MiddleTitle property={"V 1.0.0"}/>
                </Box>
            </Box>
        </Box>
    )
}

export default LoginPage;