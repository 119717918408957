import './Branches.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const MainBranch = () => {

    return (
        <Box className={"main_branch"}>
            <Box className={"main_branch_container"}>
                <Box className={"main_branch_container_title"}>
                    <MiddleTitle property={"Центральный аппарат"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FirstBranch = () => {

    return (
        <Box className={"first_branch"}>
            <Box className={"first_branch_container"}>
                <Box className={"first_branch_container_title"}>
                    <MiddleTitle property={"Актюбинский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SecondBranch = () => {

    return (
        <Box className={"second_branch"}>
            <Box className={"second_branch_container"}>
                <Box className={"second_branch_container_title"}>
                    <MiddleTitle property={"Атырауский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const ThirdBranch = () => {

    return (
        <Box className={"third_branch"}>
            <Box className={"third_branch_container"}>
                <Box className={"third_branch_container_title"}>
                    <MiddleTitle property={"ВКФ"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FourthBranch = () => {

    return (
        <Box className={"fourth_branch"}>
            <Box className={"fourth_branch_container"}>
                <Box className={"fourth_branch_container_title"}>
                    <MiddleTitle property={"ЗКФ"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const FifthBranch = () => {

    return (
        <Box className={"fifth_branch"}>
            <Box className={"fifth_branch_container"}>
                <Box className={"fifth_branch_container_title"}>
                    <MiddleTitle property={"Карагандинский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SixthBranch = () => {

    return (
        <Box className={"sixth_branch"}>
            <Box className={"sixth_branch_container"}>
                <Box className={"sixth_branch_container_title"}>
                    <MiddleTitle property={"Кызылординский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SeventhBranch = () => {

    return (
        <Box className={"seventh_branch"}>
            <Box className={"seventh_branch_container"}>
                <Box className={"seventh_branch_container_title"}>
                    <MiddleTitle property={"Мангистауский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const EighthBranch = () => {

    return (
        <Box className={"eighth_branch"}>
            <Box className={"eighth_branch_container"}>
                <Box className={"eighth_branch_container_title"}>
                    <MiddleTitle property={"Павлодарский"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const NinthBranch = () => {

    return (
        <Box className={"ninth_branch"}>
            <Box className={"ninth_branch_container"}>
                <Box className={"ninth_branch_container_title"}>
                    <MiddleTitle property={"СКФ"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const TenthBranch = () => {

    return (
        <Box className={"tenth_branch"}>
            <Box className={"tenth_branch_container"}>
                <Box className={"tenth_branch_container_title"}>
                    <MiddleTitle property={"ЮКФ"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const EleventhBranch = () => {

    return (
        <Box className={"eleventh_branch"}>
            <Box className={"eleventh_branch_container"}>
                <Box className={"eleventh_branch_container_title"}>
                    <MiddleTitle property={"Акмолинский"}/>
                </Box>
            </Box>
        </Box>
    )
}

