import './MenuCards.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";
import { CommonTitle } from "../Titles/Titles";

import { ArrowForward } from "@mui/icons-material";

export const PromptDepartureCard = () => {

    return (
        <Box className={"prompt_departure_card"}>
            <Box className={"prompt_departure_card_container"}>
                <Box className={"prompt_departure_card_container_title"}>
                    <MiddleTitle property={"Оперативные донесения"}/>
                </Box>
                <Box className={"prompt_departure_card_container_description"}>
                    <CommonTitle property={"Сервис оперативных донесений"}/>
                </Box>
                <Box className={"prompt_departure_card_container_link"}>
                    <MiddleTitle property={"Перейти"}/>
                    <ArrowForward sx={{fontSize: "20px"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const ProphylacticDepartureCard = () => {

    return (
        <Box className={"prophylactic_departure_card"}>
            <Box className={"prophylactic_departure_card_container"}>
                <Box className={"prophylactic_departure_card_container_title"}>
                    <MiddleTitle property={"Профилактические вызовы"}/>
                </Box>
                <Box className={"prophylactic_departure_card_container_description"}>
                    <CommonTitle property={"Сервис профилактических вызовов"}/>
                </Box>
                <Box className={"prophylactic_departure_card_container_link"}>
                    <MiddleTitle property={"Перейти"}/>
                    <ArrowForward sx={{fontSize: "20px"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const ObjectBaseCard = () => {

    return (
        <Box className={"object_base_card"}>
            <Box className={"object_base_card_container"}>
                <Box className={"object_base_card_container_title"}>
                    <MiddleTitle property={"База по объектам"}/>
                </Box>
                <Box className={"object_base_card_container_description"}>
                    <CommonTitle property={"Сервис баз по объектам"}/>
                </Box>
                <Box className={"object_base_card_container_link"}>
                    <MiddleTitle property={"Перейти"}/>
                    <ArrowForward sx={{fontSize: "20px"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export const TechWorkCard = () => {

    return (
        <Box className={"tech_work_card"}>
            <Box className={"tech_work_card_container"}>
                <Box className={"tech_work_card_container_title"}>
                    <MiddleTitle property={"Технические работы"}/>
                </Box>
                <Box className={"tech_work_card_container_description"}>
                    <CommonTitle property={"Сервис технических работ"}/>
                </Box>
                <Box className={"tech_work_card_container_link"}>
                    <MiddleTitle property={"Перейти"}/>
                    <ArrowForward sx={{fontSize: "20px"}}/>
                </Box>
            </Box>
        </Box>
    )
}