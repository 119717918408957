import './Avatar.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const Avatar = ({value, color}) => {

    return (
        <Box className={`avatar ${color}`}>
            <Box className={"avatar_container"}>
                <MiddleTitle property={value}/>
            </Box>
        </Box>
    )
}