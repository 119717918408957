import './ProfileMenu.scss';

import { useState } from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { logoutUser } from "../../slices/authSlice";

import { Box } from "@mui/material";

import { ArrowDropDown } from "@mui/icons-material";
import { ArrowDropUp } from "@mui/icons-material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { ProfileList } from "../../common-components/Lists/Lists";

const ProfileMenu = ({color}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const handleOpen = () => {
        setMenuOpen(!menuOpen);
    }

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    useEffect(() => {
        if (!isAuthenticated && !token) {
            navigate('/')
        }
    }, [isAuthenticated, token, navigate]);

    return (
        <Box className={"profile_menu"}>
            <Box className={"profile_menu_container"}>
                <Box className={"profile_menu_container_item"}>
                    <Box className={"profile_menu_container_item_switch"} onClick={handleOpen}>
                        <MiddleTitle property={"Alex Price"} color={color}/>
                    </Box>
                    <Box className={`profile_menu_container_item_icon ${color}`}>
                        {!menuOpen ? <ArrowDropDown/> : <ArrowDropUp/>}
                    </Box>
                </Box>
                {menuOpen && (
                    <Box className={"profile_menu_container_list"}>
                        <ProfileList handleLogout={handleLogout}/>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default ProfileMenu;