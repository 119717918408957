import './TableHeader.scss';

import { Box } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableRow } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";

const TableHeader = () => {

    return (
        <TableRow className={"table_header"}>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_number"}>
                    <CommonTitle property={"№"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_start"}>
                    <CommonTitle property={"Начало"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_finish"}>
                    <CommonTitle property={"Окончание"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_owner"}>
                    <CommonTitle property={"Владелец"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_emergency"}>
                    <CommonTitle property={"Тип аварии"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_location"}>
                    <CommonTitle property={"Объект"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_workers"}>
                    <CommonTitle property={"Чел/часы"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"left"} sx={{border: "none"}}>
                <Box className={"table_header_cell_transport"}>
                    <CommonTitle property={"Транспорт"}/>
                </Box>
            </TableCell>
            <TableCell className={"table_header_cell"} align={"center"} sx={{border: "none"}}>
                <Box className={"table_header_cell_branch"}>
                    <CommonTitle property={"Филиал"}/>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default TableHeader;