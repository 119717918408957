import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fields: {
        call_date: {
            name: 'Дата и время вызова',
            isRequired: true,
            isValid: false
        },
        caller: {
            name: 'Кто произвел вызов',
            isRequired: true,
            isValid: false
        },
        work_start_date: {
            name: 'Дата и время начала работ',
            isRequired: true,
            isValid: false
        },
        emergency_type: {
            name: 'Наименование аварийной ситуации',
            isRequired: true,
            isValid: false,
        },
        is_emergency_line: {
            name: 'Тип аварийного вызова',
            isRequired: true,
            isValid: false
        },
        emergency_info: {
            name: 'Данные по аварии',
            isRequired: false,
            isValid: false
        },
        emergency_location: {
            name: 'Место возникновения аварии',
            isRequired: true,
            isValid: false
        },
        line_facility_owner: {
            name: 'Владелец объекта',
            isRequired: true,
            isValid: false
        },
        minor_injury_count: {
            name: 'Легкая травма',
            isRequired: false,
            isValid: false
        },
        severe_injury_count: {
            name: 'Тяжелая травма',
            isRequired: false,
            isValid: false
        },
        fatal_injury_count: {
            name: 'Смертельная травма',
            isRequired: false,
            isValid: false
        },
        evacuated_people_count: {
            name: 'Эвакуировано людей',
            isRequired: false,
            isValid: false
        },
        provided_medical_count: {
            name: 'Оказана медицинская помощь',
            isRequired: false,
            isValid: false
        },
        work_scope: {
            name: 'Перечень и объем проведенных работ',
            isRequired: true,
            isValid: false
        },
        situation_during_filing: {
            name: 'Ситуация на момент подачи донесения',
            isRequired: true,
            isValid: false
        },
        transport: {
            name: 'Вид транспорта',
            isRequired: true,
            isValid: false
        },
        client_equipment_count: {
            name: 'Техника заказчика',
            isRequired: false,
            isValid: false
        },
        branch_worker_count: {
            name: 'Количество работников филиала',
            isRequired: true,
            isValid: false
        },
        worker_man_hours: {
            name: 'Количество отработанных человеко часов',
            isRequired: true,
            isValid: false
        },
        third_parties_count: {
            name: 'Количество работников сторонних организаций',
            isRequired: false,
            isValid: false
        },
        other_info: {
            name: 'Другое',
            isRequired: false,
            isValid: false
        },
    },
    allFieldsValid: false,
    alertMessage: false,
    errorMessage: null
}

const validationSlice = createSlice({
    name: 'validation',
    initialState,
    reducers: {
        validateField: (state, action) => {
            const { field, value } = action.payload;

            if (state.fields[field]) {
                const isRequired = state.fields[field].isRequired;
                const validValue = value !== null && value !== '' && value !== '0';

                state.fields[field].isValid = isRequired ? validValue : true;
                state.allFieldsValid = Object.values(state.fields).every(
                    field => field.isValid || !field.isRequired
                );
            }
        },
    }
});

export const { validateField, resetValidation } = validationSlice.actions;
export default validationSlice.reducer;