import './Pagination.scss';

import { Box } from "@mui/material";

import { NavigateNext } from "@mui/icons-material";
import { NavigateBefore } from "@mui/icons-material";

import { MiddleTitle } from "../../common-components/Titles/Titles";

const Pagination = () => {

    return (
        <Box className={"pagination"}>
            <Box className={"pagination_container"}>
                <Box className={"pagination_container_items"}>
                    <Box className={"pagination_container_items_back"}>
                        <NavigateBefore/>
                        <MiddleTitle property={"Назад"}/>
                    </Box>
                    <Box className={"pagination_container_items_current"}>
                        <MiddleTitle property={"Текущая страница 1"}/>
                    </Box>
                    <Box className={"pagination_container_items_next"}>
                        <MiddleTitle property={"Вперед"}/>
                        <NavigateNext/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Pagination;