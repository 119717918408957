import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { SevereInjuryInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const SevereInjuryField = () => {
    const doChange = useChange();
    const severeInjuryCount = useSelector((state) => state.report.severe_injury_count);
    const severeInjuryCountValid = useSelector((state) => state.validation.fields);
    const [severeProp, setSevereProp] = useState(severeInjuryCount);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={severeInjuryCountValid.severe_injury_count}
                        />
                        <MiddleTitle property={"Тяжелая травма"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <SevereInjuryInput
                            value={severeProp}
                            onChange={(e) => setSevereProp(e.target.value)}
                            onBlur={() => doChange(severeProp, 'severe_injury_count')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SevereInjuryField;