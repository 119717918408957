import '../Fields.scss';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { setWarningMessage } from "../../../slices/alertSlice";
import { showWarningMessage } from "../../../slices/alertSlice";
import { removeWarningMessage } from "../../../slices/alertSlice";

import { addTransport } from "../../../slices/reportSlice";
import { validateField } from "../../../slices/validateSlice";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { TransportDropdown } from "../../../common-components/Dropdowns/Dropdowns";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

import TransportOverview from "../../Overviews/TransportOverview/TransportOverview";

const TransportField = ({errorMessage}) => {
    const dispatch = useDispatch();
    const transport = useSelector((state) => state.report.transport);
    const transportValid = useSelector((state) => state.validation.fields);

    const transportOptions = [
        { id: "1", name: "АЦ" },
        { id: "2", name: "Оперативный а/м" },
        { id: "3", name: "РПГ а/м" },
        { id: "4", name: "Вахтовка" },
        { id: "5", name: "Легковой а/м" },
        { id: "6", name: "Легковой а/м промышленной проходимости" },
        { id: "7", name: "АСА а/м" },
    ];

    const handleSelect = (e) => {
        const selectedId = e.target.value;
        const selectedTransport = transportOptions.find(
            (option) => option.id === selectedId
        );

        const existentTransport = transport.find(
            (transport) => transport.id === selectedId
        );

        if (existentTransport) {
            const warningMessage = `${selectedTransport.name} уже был добавлен, отредактируйте количество`
            dispatch(setWarningMessage(warningMessage));
            dispatch(showWarningMessage());
        } else {
            dispatch(addTransport({...selectedTransport}));
            dispatch(validateField({field: 'transport', selectedTransport}));
            dispatch(setWarningMessage(null));
            dispatch(removeWarningMessage());
        }
    };

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={transportValid.transport}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Добавить вид транспорта"}/>
                    </Box>
                    <Box className={"field_container_dropdown_item"}>
                        <TransportDropdown
                            transportOptions={transportOptions}
                            handleSelect={handleSelect}
                            error={!transportValid.transport.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
                <Box className={"field_container_list"}>
                    <TransportOverview selectedTransports={transport}/>
                </Box>
            </Box>
        </Box>
    )
}

export default TransportField;