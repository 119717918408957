import './TableHeader.scss';

import { Box } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";

const TableHeader = () => {

    return (
        <TableRow className={"table_header"}>
            <TableCell className={"table_header_cell"} align="left" sx={{border: "none"}}>
                <CommonTitle property={"Номер"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="left" sx={{border: "none"}}>
                <CommonTitle property={"Дата"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="left" sx={{border: "none"}}>
                <CommonTitle property={"Доложил"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="left" sx={{border: "none"}}>
                <CommonTitle property={"Объект"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="left" sx={{border: "none"}}>
                <CommonTitle property={"Тип аварии"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="center" sx={{border: "none"}}>
                <CommonTitle property={"Статус"} color={"blue"}/>
            </TableCell>
            <TableCell className={"table_header_cell"} align="center" sx={{border: "none"}}>
                <CommonTitle property={"Обновить"} color={"blue"}/>
            </TableCell>
        </TableRow>
    )
}

export default TableHeader;