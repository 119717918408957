import '../Fields.scss';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { addEmergency } from "../../../slices/reportSlice";
import { validateField } from "../../../slices/validateSlice";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { EmergencyNameDropdown } from "../../../common-components/Dropdowns/Dropdowns";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

import EmergencyOverview from "../../Overviews/EmergencyOverview/EmergencyOverview";

const EmergencyField = ({errorMessage}) => {
    const dispatch = useDispatch();
    const emergencyType = useSelector((state) => state.report.emergency_type);
    const emergencyTypeValid = useSelector((state) => state.validation.fields);

    const emergencyOptions = [
        { id: "1", name: "Пожар" },
        { id: "2", name: "Травма" },
        { id: "3", name: "Отключение электроэнергии" },
        { id: "4", name: "Обрушение" },
        { id: "5", name: "Несчастный случай" },
        { id: "6", name: "Застревание" },
        { id: "7", name: "Заболевание" },
        { id: "8", name: "Затопление" },
        { id: "9", name: "ДТП" },
        { id: "10", name: "Загазованность" },
        { id: "11", name: "Взрыв" },
        { id: "12", name: "Обеспечение противопожарной" },
        { id: "13", name: "Поиск людей" },
        { id: "14", name: "Открытый фонтан" },
        { id: "15", name: "Розлив" },
        { id: "16", name: "Иное" }
    ]

    const handleSelect = (e) => {
        const selectedId = e.target.value;
        const selectedEmergency = emergencyOptions.find(
            (option) => option.id === selectedId
        );

        if (selectedEmergency) {
            dispatch(addEmergency({...selectedEmergency}));
            dispatch(validateField({field: 'emergency_type', selectedEmergency}));
        }
    }

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={emergencyTypeValid.emergency_type}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Выбор наименования ЧС"}/>
                    </Box>
                    <Box className={"field_container_items_dropdown"}>
                        <EmergencyNameDropdown
                            emergencyOptions={emergencyOptions}
                            handleSelect={handleSelect}
                            error={!emergencyTypeValid.emergency_type.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
                <Box className={"field_container_overview"}>
                    <EmergencyOverview emergency={emergencyType}/>
                </Box>
            </Box>
        </Box>
    )
}

export default EmergencyField;