import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { OtherOrganizationsInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const OrganizationsField = () => {
    const doChange = useChange();
    const thirdPartiesField = useSelector((state) => state.report.third_parties_count);
    const thirdPartiesValid = useSelector((state) => state.validation.fields);
    const [thirdPartiesProp, setThirdPartiesProp] = useState(thirdPartiesField);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager field={thirdPartiesValid.third_parties_count}/>
                        <MiddleTitle property={"Количество сторонних организаций"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <OtherOrganizationsInput
                            value={thirdPartiesProp}
                            onChange={(e) => setThirdPartiesProp(e.target.value)}
                            onBlur={() => doChange(thirdPartiesProp, 'third_parties_count')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OrganizationsField;