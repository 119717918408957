import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import Cookies from 'js-cookie';

import { APP_URL } from "../request/api";

import { request } from "../request/request";

const initialState = {
    user: {
        id: 1,
        type: 'user',
        attributes: {
            id: null,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            email_verified_at: '',
            company_id: null,
            branch_id: null,
            role_id: null,
            created_at: '',
            updated_at: '',
            role: {
                id: null,
                name: '',
                guard_name: ''
            },
            branch_office: {
                id: null,
                name: ''
            },
            company: {
                id: null,
                name: ''
            }
        },
    },
    token: Cookies.get('token') || null,
    isAuthenticated: false,
    alertMessage: false,
    successMessage: false,
    infoMessage: false,
    errorMessage: false,
}

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async(credentials, {rejectWithValue}) => {
        try {
            const body = {
                email: credentials.email,
                password: credentials.password
            }

            const response = await request(
                `${APP_URL}api/login`,
                'POST',
                body,
            );

            console.log('Server Response:', response);

            const { attributes: user, token } = response.data;
            console.log(response.data);

            Cookies.set('token', token, {path: '/', });

            return { user, token };
        } catch (error) {
            console.log(error.response.error);
            return rejectWithValue(error.response.error);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async() => {
        try {
            const token = Cookies.get('token');

            const customHeaders = {
                Authorization: `Bearer ${token}`
            };

            const response = await request(
                `${APP_URL}api/logout`,
                'POST',
                {},
                customHeaders
            );

            return response;
        } catch (error) {
            return error.response;
        } finally {
            Cookies.remove('token', {path: '/'});
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthenticated: (state, action) => {
            state.isAuthenticated = true;
            state.token = action.payload;
        },
        showAlert: (state) => {
            state.alertMessage = true
        },
        clearAlert: (state) => {
            state.alertMessage = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                if (action.payload.token) {
                    state.isAuthenticated = true;
                    state.user = action.payload.user;
                    state.token = action.payload.token;
                    state.successMessage = true;
                    state.errorMessage = false;
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.user = null;
                state.token = null;
                state.successMessage = false;
                state.errorMessage = true;
                state.infoMessage = false;
            })
            .addCase(logoutUser.fulfilled, (state, action) => {
                state.isAuthenticated = false;
                state.user = null;
                state.token = null;
                state.errorMessage = false;
                state.infoMessage = true;
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.errorMessage = true;
                state.infoMessage = false;
            })
    }
});

export const { setAuthenticated, showAlert, clearAlert } = authSlice.actions;

export default authSlice.reducer;