import '../Overviews.scss';

import { Box } from "@mui/material";

import { CommonTitle } from "../../../common-components/Titles/Titles";

import { Fire } from "../../../common-components/Helper/Helper";
import { Injury } from "../../../common-components/Helper/Helper";
import { PowerOutage } from "../../../common-components/Helper/Helper";
import { Collapse } from "../../../common-components/Helper/Helper";
import { Accident } from "../../../common-components/Helper/Helper";
import { GettingStuck } from "../../../common-components/Helper/Helper";
import { Disease } from "../../../common-components/Helper/Helper";
import { Flooding } from "../../../common-components/Helper/Helper";
import { TrafficAccident } from "../../../common-components/Helper/Helper";
import { GasPollution } from "../../../common-components/Helper/Helper";
import { Explosion } from "../../../common-components/Helper/Helper";
import { FireProtection } from "../../../common-components/Helper/Helper";
import { SearchPeople } from "../../../common-components/Helper/Helper";
import { OutdoorFountain } from "../../../common-components/Helper/Helper";
import { Bottling } from "../../../common-components/Helper/Helper";
import { Other } from "../../../common-components/Helper/Helper";

const EmergencyOverview = ({emergency}) => {

    const renderEmergencyComponent = () => {
        switch (emergency.id) {
            case "1" :
                return <Fire property={emergency.name}/>
            case "2":
                return <Injury property={emergency.name}/>
            case "3":
                return <PowerOutage property={emergency.name}/>
            case "4":
                return <Collapse property={emergency.name}/>
            case "5":
                return <Accident property={emergency.name}/>
            case "6":
                return <GettingStuck property={emergency.name}/>
            case "7":
                return <Disease property={emergency.name}/>
            case "8":
                return <Flooding property={emergency.name}/>
            case "9":
                return <TrafficAccident property={emergency.name}/>
            case "10":
                return <GasPollution property={emergency.name}/>
            case "11":
                return <Explosion property={emergency.name}/>
            case "12":
                return <FireProtection property={emergency.name}/>
            case "13":
                return <SearchPeople property={emergency.name}/>
            case "14":
                return <OutdoorFountain property={emergency.name}/>
            case "15":
                return <Bottling property={emergency.name}/>
            case "16":
                return <Other property={emergency.name}/>
            default:
                return null
        }
    }

    return (
        <Box className={"overview"}>
            <Box className={"overview_container"}>
                { emergency && emergency.id ? (
                    <Box className={"overview_container_items"}>
                        <Box className={"overview_container_items_title"}>
                            <CommonTitle property={"Выбранный тип ЧС"}/>
                        </Box>
                        <Box className={"overview_container_items_shower"}>
                            {renderEmergencyComponent()}
                        </Box>
                    </Box>
                ) : null }
            </Box>
        </Box>
    )
}

export default EmergencyOverview;