import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { EvacuatedPeopleInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const EvacuatedField = () => {
    const doChange = useChange();
    const evacuatedPeopleCount = useSelector((state) => state.report.evacuated_people_count);
    const evacuatedPeopleCountValid = useSelector((state) => state.validation.fields);
    const [evacuatedProp, setEvacuatedProp] = useState(evacuatedPeopleCount);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={evacuatedPeopleCountValid.evacuated_people_count}
                        />
                        <MiddleTitle property={"Эвакуировано людей"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <EvacuatedPeopleInput
                            value={evacuatedProp}
                            onChange={(e) => setEvacuatedProp(e.target.value)}
                            onBlur={() => doChange(evacuatedProp, 'evacuated_people_count')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default EvacuatedField;