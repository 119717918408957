import { Box } from "@mui/material";

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { LocationInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const LocationField = ({errorMessage}) => {
    const doChange = useChange();
    const emergencyLocation = useSelector((state) => state.report.emergency_location);
    const emergencyLocationValid = useSelector((state) => state.validation.fields);
    const [locationProp, setLocationProp] = useState(emergencyLocation);


    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={emergencyLocationValid.emergency_location}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Место возникновения ЧС"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <LocationInput
                            value={locationProp}
                            onChange={(e) => setLocationProp(e.target.value)}
                            onBlur={() => doChange(locationProp, 'emergency_location')}
                            error={!emergencyLocationValid.emergency_location.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default LocationField;