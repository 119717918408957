import './Layout.scss';

import { Box } from "@mui/material";

import Menu from "../Menu/Menu";
import ReportPage from "../../pages/ReportPage/ReportPage";

const Layout = ({ children }) => {

    return (
        <Box className={"layout"}>
            <Box className={"layout_container"}>
                <Box className={"layout_container_menu"}>
                    <Menu/>
                </Box>
                <Box className={"layout_container_content"}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default Layout;