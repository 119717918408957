import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { sendReport } from "../slices/reportSlice";
import { setErrorMessage } from "../slices/alertSlice";
import { showErrorMessage } from "../slices/alertSlice";
import { removeErrorMessage } from "../slices/alertSlice";

export const useSubmit = () => {
    const dispatch = useDispatch();
    const fields = useSelector((state) => state.validation.fields);
    const allFieldsValid = useSelector((state) => state.validation.allFieldsValid);

    const doSubmit = (formData) => {
        if (!allFieldsValid) {
            const invalidFields = Object.values(fields)
                .filter(field => field.isRequired && !field.isValid)
                .map(field => field.name);

            if (invalidFields.length > 0) {
                const errorMessage = invalidFields.join(',  ');
                dispatch(setErrorMessage(errorMessage));
                dispatch(showErrorMessage());
            }
        } else {
            dispatch(sendReport(formData));
            dispatch(setErrorMessage(null));
            dispatch(removeErrorMessage());
        }
    };

    return doSubmit;
}