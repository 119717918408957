import './Menu.scss';

import { Box } from "@mui/material";

import { Home } from "@mui/icons-material";
import { Assignment } from "@mui/icons-material";
import { Article } from "@mui/icons-material";
import { BarChart } from "@mui/icons-material";
import { NewReleases } from "@mui/icons-material";
import { SupportAgent } from "@mui/icons-material";
import { Map } from "@mui/icons-material"
import { Foundation } from "@mui/icons-material";

import { MiddleTitle } from "../../common-components/Titles/Titles";

import MenuItem from "../MenuItem/MenuItem";
import MenuProfile from "../MenuProfile/MenuProfile";
import Logout from "../MenuLogout/MenuLogout";
import MenuDepartment from "../MenuDepartment/MenuDepartment";

const Menu = () => {

    return (
        <Box className={"menu"}>
            <Box className={"menu_container"}>
                <Box className={"menu_container_top_section"}>
                    <MenuDepartment/>
                    <MenuItem
                        IconComponent={Home}
                        title={"Главная"}
                    />
                    <MenuItem
                        IconComponent={Assignment}
                        title={"Оперативные донесения"}
                    />
                    <MenuItem
                        IconComponent={BarChart}
                        title={"Статистика"}
                    />
                    <MenuItem
                        IconComponent={Map}
                        title={"Карта"}
                    />
                    <MenuItem
                        IconComponent={Article}
                        title={"Профилактические обследования"}
                    />
                    <MenuItem
                        IconComponent={Foundation}
                        title={"База по объектам"}
                    />
                </Box>
                <Box className={"menu_container_bottom_section"}>
                    <MenuItem
                        IconComponent={SupportAgent}
                        title={"Тех. поддержка"}
                    />
                    <MenuItem
                        IconComponent={NewReleases}
                        title={"Что нового"}
                    />
                    <MenuProfile/>
                    <Logout/>
                </Box>
            </Box>
        </Box>
    )
}

export default Menu;