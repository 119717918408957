import './MenuProfile.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { Avatar } from "../../common-components/Avatar/Avatar";

const MenuProfile = () => {
    const user = {
        name: 'Alex',
        lastName: 'Price'
    }
    const initials = `${user.name[0]}${user.lastName[0]}`;

    return (
        <Box className={"menu_profile"}>
            <Box className={"menu_profile_container"}>
                <Box className={"menu_profile_container_avatar"}>
                    <Avatar value={initials} color={"blue_circle"}/>
                </Box>
                <Box className={"menu_profile_container_title"}>
                    <MiddleTitle property={"Alex Price"}/>
                </Box>
            </Box>
        </Box>
    )
}

export default MenuProfile;