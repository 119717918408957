import './AdditionalInformation.scss';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { setFormField } from "../../../slices/reportSlice";

import { Box } from "@mui/material";

import MinorInjuryField from "../../Fields/MinorInjuryField/MinorInjuryField";
import SevereInjuryField from "../../Fields/SevereInjuryField/SevereInjuryField";
import FatalInjuryField from "../../Fields/FatalInjuryField/FatalInjuryField";
import EvacuatedField from "../../Fields/EvacuatedField/EvacuatedField";
import HealingField from "../../Fields/HealingField/HealingField";
import ScopeWorkField from "../../Fields/ScopeWorkField/ScopeWorkField";
import SituationField from "../../Fields/SituationField/SituationField";

const AdditionalInformation = ({errorMessage}) => {

    return (
        <Box className={"additional_information"}>
            <Box className={"additional_information_container"}>
                <Box className={"additional_information_container_field"}>
                    <MinorInjuryField/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <SevereInjuryField/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <FatalInjuryField/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <EvacuatedField/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <HealingField/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <ScopeWorkField errorMessage={errorMessage}/>
                </Box>
                <Box className={"additional_information_container_field"}>
                    <SituationField errorMessage={errorMessage}/>
                </Box>
            </Box>
        </Box>
    )
}

export default AdditionalInformation;