import './Chips.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../Titles/Titles";

export const SuccessChip = () => {

    return (
        <Box className={"success_chip"}>
            <Box className={"success_chip_container"}>
                <Box className={"success_chip_container_title"}>
                    <MiddleTitle property={"Ликвидировано"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const ProcessChip = () => {

    return (
        <Box className={"process_chip"}>
            <Box className={"process_chip_container"}>
                <Box className={"process_chip_container_title"}>
                    <MiddleTitle property={"В процессе"}/>
                </Box>
            </Box>
        </Box>
    )
}
