import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { SituationInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const SituationField = ({errorMessage}) => {
    const doChange = useChange();
    const situationDuringFilling = useSelector((state) => state.report.situation_during_filing);
    const situationValid = useSelector((state) => state.validation.fields);
    const [situationProp, setSituationProp] = useState(situationDuringFilling);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={situationValid.situation_during_filing}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Ситуация на момент подачи донесения"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <SituationInput
                            value={situationProp}
                            onChange={(e) => setSituationProp(e.target.value)}
                            onBlur={() => doChange(situationProp, 'situation_during_filing')}
                            error={
                                !situationValid.situation_during_filing.isValid ? errorMessage : null
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SituationField;