import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useCallback } from "react";

import { setFormField } from "../slices/reportSlice";
import { validateField } from "../slices/validateSlice";

export const useChange = () => {
    const dispatch = useDispatch();
    const fields = useSelector((state) => state.validation.fields);

    const doChange = useCallback((value, field) => {
        const isRequired = fields[field]?.isRequired;

        dispatch(setFormField({field: field, value}));
        dispatch(validateField({field: field, value, isRequired}));
    }, [dispatch, fields]);

    return doChange;
}