import './IntervalFilter.scss';

import { Box } from "@mui/material";

import { Filter } from "../../common-components/Dropdowns/Dropdowns";

const IntervalFilter = () => {
    const options = [
        {
            id: '1',
            name: 'За неделю'
        },
        {
            id: '2',
            name: 'За месяц'
        },
        {
            id: '3',
            name: 'За год'
        },
        {
            id: '4',
            name: 'Диапазон'
        }
    ]

    return (
        <Box className={"interval_filter"}>
            <Filter options={options} propertyLabel={"Интервал"}/>
        </Box>
    )
}

export default IntervalFilter;