import './Lists.scss';

import { Box } from "@mui/material";

import { AccountCircle } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";

import { MiddleTitle } from "../Titles/Titles";

export const ProfileList = ({handleLogout}) => {

    return (
        <Box className={"profile_list"}>
            <Box className={"profile_list_container"}>
                <Box className={"profile_list_container_item"}>
                    <Box className={"profile_list_container_item_icon"}>
                        <AccountCircle fontSize={"large"}/>
                    </Box>
                    <Box className={"profile_list_container_item_link"}>
                        <MiddleTitle property={"Профиль"}/>
                    </Box>
                </Box>
                <Box className={"profile_list_container_item"} onClick={handleLogout}>
                    <Box className={"profile_list_container_item_icon"}>
                        <Logout fontSize={"large"}/>
                    </Box>
                    <Box className={"profile_list_container_item_link"}>
                        <MiddleTitle property={"Выйти"}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}