import './Breadcrumbs.scss';

import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom"

import { CommonTitle } from "../../common-components/Titles/Titles";

const Breadcrumbs = () => {
    const location = useLocation();
    const param = location.pathname.split('/').filter((point) => point);
    const translations = {
        '' : 'Главная',
        'ReportPage' : 'Таблица оперативных донесений',
        'DeparturePage': 'Оперативные выезды',
        'DetailsPage' : 'Детальная информация',
    }

    return (
        <nav className={"breadcrumbs"}>
            <ul className={"breadcrumbs_container"}>
                <li className={"breadcrumbs_container_list"}>
                    <Link className={"breadcrumbs_container_list_link"} to="/">
                        {translations[""]}
                    </Link>
                </li>
                {param.map((value, index) => {
                    const to = `/${param.slice(0, index + 1).join('/')}`;
                    const translatedValue = translations[value] || value;
                    return (
                        <li className={"breadcrumbs_container_list"} key={to}>
                            <Link className={"breadcrumbs_container_list_link current_link"} to={to}>
                                {translatedValue}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    )
}

export default Breadcrumbs;