import './MenuItem.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";

const MenuItem = ({IconComponent, title}) => {

    return (
        <Box className={"menu_item"}>
            <Box className={"menu_item_container"}>
                <Box className={"menu_item_container_icon"}>
                    <IconComponent/>
                </Box>
                <Box className={"menu_item_container_title"}>
                    <MiddleTitle property={title}/>
                </Box>
            </Box>
        </Box>
    )
}

export default MenuItem;