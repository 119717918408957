import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { FinishDateInput } from "../../../common-components/Inputs/Inputs";
import { AdditionallyIndicator } from "../../../common-components/Indicator/Indicator";

const FinishDateField = () => {

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <AdditionallyIndicator/>
                        <MiddleTitle property={"Дата и время окончания работ"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <FinishDateInput/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FinishDateField;