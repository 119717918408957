import './MenuDepartment.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { Department } from "../../common-components/Department/Department";

const MenuDepartment = () => {
    const department = {
        name: 'РЦШ ПВАСС'
    }
    const initials = `${department.name[0]}`;

    return (
        <Box className={"menu_department"}>
            <Box className={"menu_department_container"}>
                <Box className={"menu_department_container_icon"}>
                    <Department value={initials} color={"blue_department"}/>
                </Box>
                <Box className={"menu_department_container_title"}>
                    <MiddleTitle property={department.name}/>
                </Box>
            </Box>
        </Box>
    )
}

export default MenuDepartment;