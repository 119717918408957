import './DetailsItem.scss';

import { Box } from "@mui/material";

import { MiddleTitle } from "../../common-components/Titles/Titles";
import { CommonTitle } from "../../common-components/Titles/Titles";

const DetailsItem = ({detailsTitle, detailsValue}) => {

    return (
        <Box className={"details_item"}>
            <Box className={"details_item_container"}>
                <Box className={"details_item_container_title"}>
                    <MiddleTitle property={detailsTitle}/>
                </Box>
                <Box className={"details_item_container_description"}>
                    <CommonTitle property={detailsValue}/>
                </Box>
            </Box>
        </Box>
    )
}

export default DetailsItem;