import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    warningAlert: false,
    errorAlert: false,
    errorMessage: null,
    warningMessage: null,
    successMessage: null,
    infoMessage: null,
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showWarningMessage: (state) => {
            state.warningAlert = true;
            state.errorAlert = false;
        },
        removeWarningMessage: (state) => {
            state.warningAlert = false;
        },
        showErrorMessage: (state) => {
            state.warningAlert = false;
            state.errorAlert = true
        },
        removeErrorMessage: (state) => {
            state.errorAlert = false;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setWarningMessage: (state, action) => {
            state.warningMessage = action.payload;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setInfoMessage: (state, action) => {
            state.infoMessage = action.payload;
        }
    }
});

export const { showWarningMessage, removeWarningMessage, showErrorMessage, removeErrorMessage, setErrorMessage, setWarningMessage, setSuccessMessage, setInfoMessage } = alertSlice.actions;
export default alertSlice.reducer;