import '../Fields.scss';

import { useSelector } from "react-redux";

import { useChange } from "../../../hooks/useChange";

import { useState } from "react";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { FatalInjuryInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const CallDateField = () => {
    const doChange = useChange();
    const fatalInjuryCount = useSelector((state) => state.report.fatal_injury_count);
    const fatalInjuryCountValid = useSelector((state) => state.validation.fields);
    const [fatalProp, setFatalProp] = useState(fatalInjuryCount);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={fatalInjuryCountValid.fatal_injury_count}
                        />
                        <MiddleTitle property={"Смертельная травма"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <FatalInjuryInput
                            value={fatalProp}
                            onChange={(e) => setFatalProp(e.target.value)}
                            onBlur={() => doChange(fatalProp, 'fatal_injury_count')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CallDateField;