import '../Fields.scss';

import {useDispatch, useSelector} from "react-redux";

import { addOperationsType } from "../../../slices/reportSlice";
import { validateField } from "../../../slices/validateSlice";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { TypeEmergencyDropdown } from "../../../common-components/Dropdowns/Dropdowns";

import TypeOverview from "../../Overviews/TypeOwerview/TypeOverview";
import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const TypeField = ({errorMessage}) => {
    const dispatch = useDispatch();
    const operationsType = useSelector((state) => state.report.is_emergency_line);
    const operationsTypeValid = useSelector((state) => state.validation.fields);

    const handleSelect = (e) => {
        const selectedId = e.target.value;

        if (selectedId === 'line_emergency') {
            dispatch(addOperationsType(true));
            dispatch(validateField({field: 'is_emergency_line', selectedId, isRequired: true}));
        } else if (selectedId === 'agreement_emergency') {
            dispatch(addOperationsType(false));
            dispatch(validateField({field: 'is_emergency_line', selectedId, isRequired: true}));
        }
    }

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager
                            field={operationsTypeValid.is_emergency_line}
                            errorMessage={errorMessage}
                        />
                        <MiddleTitle property={"Выбор типа оперативного вызова"}/>
                    </Box>
                    <Box className={"field_container_items_dropdown"}>
                        <TypeEmergencyDropdown
                            handleChange={handleSelect}
                            error={!operationsTypeValid.is_emergency_line.isValid ? errorMessage : null}
                        />
                    </Box>
                </Box>
                <Box className={"field_container_overview"}>
                    <TypeOverview type={operationsType}/>
                </Box>
            </Box>
        </Box>
    )
}

export default TypeField;