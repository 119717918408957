import '../Fields.scss';

import { useSelector } from "react-redux";

import { useState } from "react";

import { useChange } from "../../../hooks/useChange";

import { Box } from "@mui/material";

import { MiddleTitle } from "../../../common-components/Titles/Titles";
import { OtherInput } from "../../../common-components/Inputs/Inputs";

import IndicatorManager from "../../IndicatorManager/IndicatorManager";

const OtherInfoField = () => {
    const doChange = useChange();
    const otherInfoField = useSelector((state) => state.report.other_info);
    const otherInfoValid = useSelector((state) => state.validation.fields);
    const [otherInfoProp, setOtherInfoProp] = useState(otherInfoField);

    return (
        <Box className={"field"}>
            <Box className={"field_container"}>
                <Box className={"field_container_items"}>
                    <Box className={"field_container_items_title"}>
                        <IndicatorManager field={otherInfoValid.other_info}/>
                        <MiddleTitle property={"Другое"}/>
                    </Box>
                    <Box className={"field_container_items_input"}>
                        <OtherInput
                            value={otherInfoProp}
                            onChange={(e) => setOtherInfoProp(e.target.value)}
                            onBlur={() => doChange(otherInfoProp, 'other_info')}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OtherInfoField;