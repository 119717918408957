import './Titles.scss';

import { Box } from "@mui/material";

export const LargeTitle = ({property, color}) => {

    return (
        <Box className={"large_title"}>
            <Box className={"large_title_container"}>
                <Box className={`large_title_container_item ${color}`}>
                    {property}
                </Box>
            </Box>
        </Box>
    )
}

export const MiddleTitle = ({property, color}) => {

    return (
        <Box className={"middle_title"}>
            <Box className={"middle_title_container"}>
                <Box className={`middle_title_container_item ${color}`}>
                    {property}
                </Box>
            </Box>
        </Box>
    )
}

export const CommonTitle = ({property, color}) => {

    return (
        <Box className={"common_title"}>
            <Box className={"common_title_container"}>
                <Box className={`common_title_container_item ${color}`}>
                    {property}
                </Box>
            </Box>
        </Box>
    )
}